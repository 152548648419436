import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="118vh"
          maxHeight="800px"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="1px 1px 3px rgba(0,0,0,.6)"
          column
          alignCenter
        >
          <CFView textCenter w="100%">
            <CFView mt="4vh">
              <CFImage
                w="90%"
                src={mobileHeroText}
                alt="Tokai Ramen hero text"
                zIndex={98}
              />
            </CFView>
            <CFView pulsate mt="20px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="650px"
          w="100%"
          image={`url(${hero}) 50% 0 / cover no-repeat`}
          zIndex={90}
          column
          justifyStart
          alignStart
        >
          <CFView column justifyCenter alignCenter ml="5%" h="100vh">
            <CFImage
              w="90vh"
              maxWidth="550px"
              src={heroText}
              alt="Tokai Ramen hero text"
              zIndex={98}
            />
            <CFView mv="30px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
